import useIsSmallNav from '@apps/www/src/www/hooks/useIsSmallNav';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import { useState } from 'react';

export interface ISocialProof {
	title: string;
	userDescription: string;
	userName: string;
	avatarURL: string;
}

const SVSocialProfileGrid = ({
	SOCIAL_PROOF_LIST,
	showMoreCopy,
}: {
	SOCIAL_PROOF_LIST: ISocialProof[];
	showMoreCopy: string;
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const isSmallNav = useIsSmallNav();

	const visibleItems = isSmallNav && !isOpen ? SOCIAL_PROOF_LIST.slice(0, 5) : SOCIAL_PROOF_LIST;

	return (
		<SVPageMargins>
			<div className="flex-center relative mx-auto w-full max-w-screen-xl">
				<div className="-sm:columns-1 -md:columns-2 -lg:columns-3 relative columns-4 gap-8 space-y-8">
					{visibleItems.map((item, index) => (
						<div
							key={index}
							className="break-inside-avoid rounded-xl border border-gray-800 p-4 text-center"
						>
							<div className="text-xl text-gray-500">{item.title}</div>
							<div className="flex-center mt-4">
								<SVImage
									src={item.avatarURL}
									className="h-5 w-5 rounded-full"
									alt={item.userName + ' avatar'}
								/>
							</div>
							<div className="mt-3 text-lg text-gray-300">{item.userName}</div>
							<div className="text-sm text-gray-500">{item.userDescription}</div>
						</div>
					))}
					{isSmallNav && !isOpen ? (
						<div className="absolute bottom-0 left-0 right-0 mt-5 flex h-[300px] items-end justify-center bg-gradient-to-t from-black to-transparent">
							<SVButton
								use={SVButtonUSES.PRIMARY}
								onClick={() => setIsOpen(true)}
								className="mb-5 w-fit px-8 py-6 font-medium"
							>
								{showMoreCopy}
							</SVButton>
						</div>
					) : null}
				</div>
			</div>
		</SVPageMargins>
	);
};

export default SVSocialProfileGrid;
