/**
 * This hook animates css properties on elements based on their container position relative to the viewport.
 */

import useElementBoudingClientRectUpdated from '@apps/www/src/www/hooks/useElementBoudingClientRectUpdated';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import React, { useEffect, useRef } from 'react';

type Measurements = {
	offset: number;
	travel: number;
	windowWidth: number;
	windowHeight: number;
};

export default function useScrollAnimation({
	containerRef,
	update: updateFn, // progress goes from 0 to 1
	topThreshold = 0, // % of container visible when progress should be at 0 (0 is when container top is touching the top of the viewport, starting to hide awai)
	bottomThreshold = 1, // % of container visible when progress should be at 1 (1 is when container top is touching the bottom of the viewport, about to show up)
	clamp = false,
	enableAnimationAlways = false,
}: {
	containerRef: React.RefObject<HTMLDivElement>;
	update: (progress: number, measurements: Measurements) => void;
	topThreshold?: number;
	bottomThreshold?: number;
	clamp?: boolean;
	enableAnimationAlways?: boolean;
}) {
	const measurementsRef = useRef<Measurements>({
		offset: 0,
		travel: 1,
		windowWidth: 0,
		windowHeight: 0,
	});
	const lastProgressRef = useRef<number | null>(null);

	const updateMeasurements = useEventCallback(() => {
		if (!containerRef.current) {
			return;
		}

		const boundingClientRect = containerRef.current.getBoundingClientRect();

		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;
		const documentHeight = document.documentElement.scrollHeight;
		const containerTop = boundingClientRect.top + window.scrollY;
		const containerHeight = boundingClientRect.height;

		// For elements that are at the bottom of the document, they might not be able to travel all the way to 1
		const bottomCompensation = Math.max(
			0,
			windowHeight - (documentHeight - (containerTop + containerHeight)),
		);

		let offset =
			containerTop + containerHeight - windowHeight * topThreshold - bottomCompensation; // top of container
		const offsetEnd = containerTop - windowHeight * bottomThreshold; // bottom of container

		let travel = Math.max(offset - offsetEnd, 1); // min 1 to prevent division by 0

		offset -= travel * topThreshold;

		travel *= bottomThreshold - topThreshold;

		measurementsRef.current = {
			offset,
			travel,
			windowWidth,
			windowHeight,
		};

		update();
	});

	useElementBoudingClientRectUpdated(containerRef.current, updateMeasurements);

	useEffect(() => {
		updateMeasurements();
	}, [updateMeasurements, topThreshold, bottomThreshold, clamp]);

	const update = useEventCallback(() => {
		const measurements = measurementsRef.current;

		// clamp between -1.5 and 1.5 so we don't update too far out of viewport
		let progress = Math.max(
			-1.5,
			Math.min(1.5, 1 - (measurements.offset - window.scrollY) / measurements.travel),
		);

		if (clamp) {
			progress = Math.max(0, Math.min(1, progress));
		}

		// Just update if progress is increasing or if we're always animating
		if (
			progress !== lastProgressRef.current &&
			(enableAnimationAlways ||
				progress > (lastProgressRef.current || 0) ||
				lastProgressRef.current === null)
		) {
			lastProgressRef.current = progress;
			updateFn(progress, measurements);
		}
	});

	useEffect(() => {
		window.addEventListener('scroll', update);

		return () => {
			window.removeEventListener('scroll', update);
		};
	}, [update]);
}
